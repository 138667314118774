<template>
  <div class="container">
    <div class="row text-center">
      <div class="col-2 mx-auto">
        <h4 class="fw-bold">Cookieポリシー</h4>
      </div>
    </div>
    <div class="row text-start mt-4">
      <div class="col-6 mx-auto">
        <p>
          公益財団法人日本テニス協会（以下、「当協会」といいます。）では、ウェブサイトにおけるお客様の利用状況を分析するため、あるいは個々のお客様に対してよりカスタマイズされたサービス・広告を提供する等の目的のため、Cookieおよび類似技術を使用して一定の情報を収集する場合があります。
        </p>
      </div>
    </div>
    <div class="row text-first mt-5">
      <div class="col-6 mx-auto">
        <h6>
          1. Cookieについて
        </h6>
        <p class="mt-2">
          「Cookie」とは、お客様がウェブサイトにアクセスした時に、ウェブサーバからお客様のコンピューターまたはモバイルデバイス（以下あわせて「デバイス」といいます）のブラウザに送信され、デバイスのハードディスクドライブに保存される小さなテキストファイルです。
        </p>
        <p>
          Cookieは、一般的に「1st Party Cookie」と「3rd Party Cookie」という2種類のカテゴリーに大別されます。「1st Party
          Cookie」は、当協会によって設定されます。このCookieは、ほとんどの場合、当協会ウェブサイト上で提供される機能を利用するために設けられています。一方で、「3rd Party
          Cookie」は、当協会によってではなく、当協会が提携する第三者によって設定されます。例えば、3rd Party
          Cookieの一部は、当協会が提携する広告配信サービス提供会社によって、適切なオンライン広告を適切な場所に掲載することができるようお客様のブラウジング活動を追跡するため
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiePolicy"
}
</script>

<style scoped>

</style>